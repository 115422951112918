import React from "react"
import Layout from "../layout/layout"
import SEO from "../components/seo"

const LatestPage = () => (
  <Layout>
    <SEO title="Latest Post" />
    <div>
      <h1>About Us</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis
        adipisci, voluptatum, nisi velit praesentium error quia explicabo
        voluptas ad recusandae enim. Recusandae sequi non vel voluptas. Labore
        omnis provident ex, perferendis esse, voluptate atque alias officiis
        dolor eligendi itaque qui?
      </p>
    </div>
  </Layout>
)

export default LatestPage
